import brand from '../brand';

import type { JsonApiPayload } from './api.interfaces';

// TODO: find a better way for a fully-typed JsonAPI builder
export function buildPayload<P extends Record<string, unknown>>(p: P): JsonApiPayload<P> {
  return {
    data: {
      type: 'registration', // enum API.REGISTRATION.slice(0, -1) ?
      attributes: p,
      relationships: {
        brand: {
          data: { type: 'brand', id: brand.id },
        },
      },
    },
  };
}

export function getValueFromEnvironmentOrThrow(key: string) {
  const ENV_VALUE = process.env[`REACT_APP_${key.toUpperCase()}`];
  if (!ENV_VALUE) throw new Error(`Environment variable "${key.toUpperCase()}" not defined`);
  return ENV_VALUE;
}

/**
 * Generates a properly formatted/typed Error object which can be exploited in views
 *
 * If an error code is returned from API, this routine detects it and swaps :
 *
 * - the business error code into error.name
 * - associated business message into error.message
 *
 * ... otherwise it returns a generic Error object
 */
export function errorHandler<BusinessErrorCodes extends string>(
  err: unknown,
  businessErrors: Record<BusinessErrorCodes, string>,
): Error {
  const error = err as Error;
  const e = new Error();

  if (Object.keys(businessErrors).includes(error.message)) {
    e.message = businessErrors[error.message as BusinessErrorCodes];
    e.name = error.message;
  } else {
    console.error(`Unknown Error: ${error.message}`);
    e.message = `Une erreur inconnue est survenue`;
    e.name = 'Unknown error';
  }

  return e;
}
