import React, { ForwardedRef, forwardRef } from 'react';

import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  OutlinedInputProps,
} from '@mui/material';
import { useToggle } from 'react-use';

interface PasswordProps extends OutlinedInputProps {
  errorMessage?: string;
  forwardedRef?: ForwardedRef<typeof OutlinedInput>;
}

function BasePasswordField({ errorMessage, forwardedRef, ...otherProps }: PasswordProps) {
  const [showPassword, toggleShowPassword] = useToggle(false);

  return (
    <FormControl variant="outlined">
      <InputLabel error={Boolean(errorMessage)} htmlFor="password-input">
        Mot de passe
      </InputLabel>
      <OutlinedInput
        id="password-input"
        type={showPassword ? 'text' : 'password'}
        label="Mot de passe"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Afficher/masquer le mot de passe"
              onClick={toggleShowPassword}
              onMouseDown={(event) => event.preventDefault()}
              edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        error={Boolean(errorMessage)}
        {...otherProps}
        ref={forwardedRef}
      />
      <FormHelperText>
        6 caractères minimum. Seuls ces caractères spéciaux sont autorisés @ $ & _ ( ) : ; ! # - / % ?.
      </FormHelperText>
      {errorMessage ? <FormHelperText error={Boolean(errorMessage)}>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}

const PasswordField = forwardRef<typeof OutlinedInput, PasswordProps>((props, ref) => (
  <BasePasswordField {...props} forwardedRef={ref} />
));

PasswordField.displayName = 'PasswordField';

export default PasswordField;
