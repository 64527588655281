import React, { ElementType } from 'react';

import Stack, { StackProps } from '@mui/material/Stack';

const HorizontalStack = <D extends ElementType>({ children, ...props }: StackProps<D>) => (
  <Stack direction="row" alignItems="center" spacing={1} {...props}>
    {children}
  </Stack>
);

export default HorizontalStack;
