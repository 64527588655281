import React from 'react';

import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

import Spacer from './Spacer';

export default function SubmitButton({ children, ...otherProps }: LoadingButtonProps) {
  const theme = useTheme();

  return (
    <>
      <Spacer style={{ minHeight: theme.spacing(5) }} />

      <LoadingButton type="submit" size="large" {...otherProps}>
        <Typography variant="button">{children}</Typography>
      </LoadingButton>
    </>
  );
}
