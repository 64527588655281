import React from 'react';

import { grey } from '@mui/material/colors';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { useMeasure, useWindowSize } from 'react-use';

import brand from '../../brand';
import HorizontalStack from '../../components/HorizontalStack';

export default function Footer() {
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const [ref1, { width: spanWidth }] = useMeasure<HTMLSpanElement>();
  const [ref2, { width: anchorWidth }] = useMeasure<HTMLAnchorElement>();
  const SPACING = 3;
  const WINDOW_PADDING = 2 * 8;
  // This logic prevents the 2 text blocks to go offscreen when viewport width is narrow,
  // switching from a one-line layout, to 2 lines centered
  const shouldWrap = spanWidth + SPACING * 8 + anchorWidth > width - 2 * WINDOW_PADDING;

  return (
    <HorizontalStack
      component="footer"
      flexDirection={shouldWrap || pathname === '/mentions_legales' ? 'column' : 'row'}
      justifyContent="space-between"
      flexWrap="wrap"
      spacing={shouldWrap ? 0 : SPACING}
      mt={3}
      pt={2}
      sx={{ borderTop: `1px solid ${grey[200]}` }}>
      <Typography variant="body2" ref={ref1}>
        © {brand.name}, tous droits réservés.
      </Typography>

      {pathname !== '/mentions_legales' ? (
        <Link component={ReactRouterLink} to="/mentions_legales" state={{ from: pathname }} ref={ref2}>
          <Typography variant="subtitle2" fontWeight="fontWeightSemiBold" color="primary.main">
            Mentions légales
          </Typography>
        </Link>
      ) : null}
    </HorizontalStack>
  );
}
