import { isBefore, isValid, subYears } from 'date-fns';

import { CONFIRMATION_CODE_LENGTH } from './signup.constants';
import type { RideCodePath } from './signup.interfaces';

/** Builds an URL based on ride code */
export function buildPathname(rideCode: string, path: RideCodePath = '') {
  return `/i/${rideCode}/${path}`;
}

/**
 * Vérifie si un numéro international "brut" (sans espace ni signe "+") est valide.
 * @param phoneNumber 33678901234
 * @return Si le numéro est valide ou non.
 */
export function validateRawInternationalPhoneNumber(phoneNumber: string) {
  return /^\d{2}[1-9]\d{8}$/.test(phoneNumber.replaceAll(/[^+\d]/g, ''));
}

/** Tests if input consists of exactly <CONFIRMATION_CODE_LENGTH> alphanumeric chars */
export function isCodeValid(c?: string) {
  return RegExp(`[\\w\\d]{${CONFIRMATION_CODE_LENGTH}}`).test(c ?? '');
}

/** Replaces at given index with value (or null in case of deletion) */
export function replaceAt(stringArray: (string | null)[] = [], value: string, index: number) {
  if (!stringArray.length || index < 0 || index >= stringArray.length) return stringArray;
  const result = [...stringArray];
  result[index] = value.trim() || null;
  return result;
}

export function isValidDate(date: string) {
  return isValid(date) || 'Date invalide';
}

export function isLegalForDriving(date: string) {
  return isBefore(new Date(date), subYears(Date.now(), 18)) || "Âge inférieur à l'âge légal de conduite";
}

/**
 *
 * @param phoneNumber Un numéro de téléphone international "brut" (sans espace ni signe "+")
 * @returns Le numéro de téléphone sans le 0 après l'indicatif de pays.
 */
export function cleanRawInternationalPhoneNumber(phoneNumber = '') {
  return phoneNumber.replace(/^(\d{2})0(\d+)$/, '$1$2');
}
