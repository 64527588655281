import React from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

import HorizontalStack from './HorizontalStack';

export default function BackButton({
  children,
  icon,
  toRight = false,
  sx,
  ...otherProps
}: ButtonProps & { toRight?: boolean; icon?: ButtonProps['startIcon'] }) {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <HorizontalStack
      spacing={children && icon ? 2 : 0}
      sx={{ position: 'absolute', top: 48, left: toRight ? 'auto' : 16, right: toRight ? 16 : 'auto', ...sx }}>
      {children ? (
        <Button variant="text" fullWidth={false} onClick={goBack} startIcon={icon} sx={{ padding: 0 }} {...otherProps}>
          {children}
        </Button>
      ) : (
        <IconButton onClick={() => navigate(-1)} sx={{ padding: 0 }} {...otherProps}>
          {icon}
        </IconButton>
      )}
    </HorizontalStack>
  );
}
