type CountryCode = 'fr' | 'ch';

type Country = { code: CountryCode; phoneMask: string };

const FRANCE: Country = { code: 'fr', phoneMask: '(..) . .. .. .. ..' };
const SWISS: Country = { code: 'ch', phoneMask: '(..) ... .. .. ..' };

export interface Brand {
  id: number;
  name: string;
  logoUrl: string;
  eulaUrl: string;
  privacyPolicyUrl: string;
  downloadUrl: string;
  logoHeight: number;
  supportMail: string;
  supportPhoneNumber: string;
  palette: {
    primary: {
      main: string;
      light: string;
    };
    secondary: {
      main: string;
      light: string;
    };
  };
  countries: [Country, ...Country[]];
}

type BaseBrand = Omit<Brand, 'id'>;

const LOGO_HEIGHT = 40;

const covoitici: BaseBrand = {
  name: "Covoit'ici",
  logoUrl: '/covoitici/logo.png',
  eulaUrl: 'https://www.covoitici.fr/conditions-generales/',
  privacyPolicyUrl: 'https://www.covoitici.fr/politique-de-protection-des-donnees-a-caractere-personnel/',
  downloadUrl: 'https://go.covoitici.fr',
  logoHeight: LOGO_HEIGHT,
  supportMail: 'support@covoitici.fr',
  supportPhoneNumber: '0179738973',
  palette: {
    primary: {
      main: '#0076AA',
      light: '#E0F6FF',
    },
    secondary: {
      main: '#FFBE00',
      light: '#FFEBB2',
    },
  },
  countries: [FRANCE],
};

const heleman: BaseBrand = {
  name: 'Hé!Léman',
  logoUrl: '/heleman/logo.png',
  eulaUrl: 'https://www.covoiturage-leman.org/mentions-legales',
  privacyPolicyUrl: 'https://www.covoiturage-leman.org/mentions-legales',
  downloadUrl: 'https://go.covoiturage-leman.org',
  supportMail: 'support@heleman.org',
  supportPhoneNumber: '0481695100',
  logoHeight: LOGO_HEIGHT,
  palette: {
    primary: {
      main: '#005F88',
      light: '#B7D4E1',
    },
    secondary: {
      main: '#EC6129',
      light: '#F9CFBE',
    },
  },
  countries: [FRANCE, SWISS],
};

const inscriptionstart: BaseBrand = {
  name: "star't",
  logoUrl: '/start/logo.png',
  eulaUrl: 'https://www.star.fr/cgv-cgu',
  privacyPolicyUrl: 'https://www.star.fr/mentions-legales/politique-de-confidentialite',
  downloadUrl: 'https://gostart.star.fr',
  supportMail: 'startcontact@star.fr',
  supportPhoneNumber: '0481922704',
  logoHeight: LOGO_HEIGHT,
  palette: {
    primary: {
      main: '#224897',
      light: '#B9C0D1',
    },
    secondary: {
      main: '#53EB89',
      light: '#CBF9DB',
    },
  },
  countries: [FRANCE],
};

const lane: BaseBrand = {
  name: 'Lane',
  logoUrl: '/lane/logo.png',
  eulaUrl: 'https://lanemove.com/conditions-generales-dutilisation/',
  privacyPolicyUrl: 'https://www.lanemove.com/politique-de-protection-des-donnees-a-caractere-personnel/',
  downloadUrl: 'https://go.lanemove.com',
  supportMail: 'help@lanemove.com',
  supportPhoneNumber: '0426699898',
  logoHeight: LOGO_HEIGHT,
  palette: {
    primary: {
      main: '#5E04A2',
      light: '#EEE5F5',
    },
    secondary: {
      main: '#FF7700',
      light: '#FFD6B2',
    },
  },
  countries: [FRANCE],
};

const mcovoit: BaseBrand = {
  name: "M'Covoit",
  logoUrl: '/mcovoit/logo.png',
  eulaUrl: 'https://www.lignesplus-m.fr/conditions-generales/',
  privacyPolicyUrl: 'https://www.lignesplus-m.fr/politique-de-protection-des-donnees-a-caractere-personnel/',
  downloadUrl: 'https://go.lignesplus-m.fr',
  supportMail: 'support@lignesplus-m.fr',
  supportPhoneNumber: '0481695010',
  logoHeight: 64,
  palette: {
    primary: {
      main: '#007F49',
      light: '#DFF7ED',
    },
    secondary: {
      main: '#FCC000',
      light: '#FFF1C4',
    },
  },
  countries: [FRANCE],
};

const oncovoit: BaseBrand = {
  name: "OnCovoit'",
  logoUrl: '/oncovoit/logo.png',
  eulaUrl: 'https://oncovoit.coeurdesavoie.fr/conditions-generales/',
  privacyPolicyUrl: 'https://oncovoit.coeurdesavoie.fr/politique-de-protection-des-donnees-a-caractere-personnel/',
  downloadUrl: 'https://go.covoitici.fr',
  supportMail: 'oncovoit@coeurdesavoie.fr',
  supportPhoneNumber: '0757596937',
  logoHeight: LOGO_HEIGHT,
  palette: {
    primary: {
      main: '#017F46',
      light: '#EAF3EF',
    },
    secondary: {
      main: '#6223B8',
      light: '#EFE4FF',
    },
  },
  countries: [FRANCE],
};

// See https://bo.covoitici.fr/admin/brands?order=id_asc
// See https://backoffice.staging.covoitici.fr/admin/brands?order=id_asc
type hostname = string;
const mapping: Record<hostname, Brand> = {
  'covoitici.ecov.me': { ...covoitici, id: 1 },
  'covoitici.staging.ecov.me': { ...covoitici, id: 1 },

  'mcovoit.ecov.me': { ...mcovoit, id: 2 },
  'mcovoit.staging.ecov.me': { ...mcovoit, id: 3 },

  'lane.ecov.me': { ...lane, id: 3 },
  'lane.staging.ecov.me': { ...lane, id: 7 },

  'heleman.ecov.me': { ...heleman, id: 5 },
  'heleman.staging.ecov.me': { ...heleman, id: 9 },

  'oncovoit.ecov.me': { ...oncovoit, id: 7 },
  'oncovoit.staging.ecov.me': { ...oncovoit, id: 10 },

  'inscriptionstart.star.fr': { ...inscriptionstart, id: 4 },
  'inscriptionstart-staging.star.fr': { ...inscriptionstart, id: 4 },
};

/**
 * Note for development convenience, you can set a given brand to the desired brand selector
 * Doing this will prevent you getting error 422 from requests requiring a ride code matching the brand ;)
 *
 * Note2: on test environment, we deliberately choose a different one than the one used for dev
 */
function getBrandProfile(): Brand {
  if (process.env.NODE_ENV === 'development') {
    return { ...covoitici, id: 1 };
  } else if (process.env.NODE_ENV === 'test') {
    return { ...oncovoit, id: 10 };
  } else {
    const brand = mapping[window.location.host];
    if (brand !== undefined) {
      return brand;
    } else {
      throw new Error('Unknown host: ' + window.location.host);
    }
  }
}

export default getBrandProfile();
