import { useLayoutEffect } from 'react';

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMount } from 'react-use';

import { Step } from '../../components/Stepper';

import { IS_PHONE_CONFIRMED_CACHE_KEY, PHONE_NUMBER_CACHE_KEY, RIDE_CODE_PARAM } from './signup.constants';
import { buildPathname } from './signup.helpers';
import type { SetActiveStep } from './signup.interfaces';

export function useRideCode() {
  const { rideCode } = useParams<typeof RIDE_CODE_PARAM>();
  if (!rideCode) {
    throw Error(`Expected rideCode to be truthy, but got: ${JSON.stringify(rideCode)}`);
  }
  return rideCode;
}

export function useStepper(step: Step) {
  const setActiveStep = useOutletContext<SetActiveStep>();
  // Prevent layout cascades with useLayoutEffect
  useLayoutEffect(() => {
    setActiveStep(step);
  }, [setActiveStep, step]);
}

/** Redirects to previous page if user goes directly to one step page, bypassing required data */
export function useRedirectOnIncompleteInfo() {
  const navigate = useNavigate();
  const rideCode = useRideCode();
  const cachedPhoneNumber = sessionStorage.getItem(PHONE_NUMBER_CACHE_KEY) || undefined;
  const isPhoneConfirmed = !!sessionStorage.getItem(IS_PHONE_CONFIRMED_CACHE_KEY);

  useMount(() => {
    // Case where user would go directly to the confirmation page without sending his phone
    if (!cachedPhoneNumber) {
      const pathname = buildPathname(rideCode);
      if (window.location.pathname !== pathname) {
        console.info(`Phone number is missing ! Redirecting to ${pathname}`);
        navigate(pathname);
      }
    }
    // Case where user would go directly to the last form without confirming his phone
    else if (!isPhoneConfirmed) {
      const pathname = buildPathname(rideCode, 'confirmation_telephone');
      if (window.location.pathname !== pathname) {
        console.info(`Phone is not confirmed ! Redirecting to ${pathname}`);
        navigate(pathname);
      }
    }
  });
}
