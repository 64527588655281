import React, { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

import App from './App';
import brand from './brand';
import { isDev } from './helpers';
import reportWebVitals from './reportWebVitals';
import './index.css';

const container = document.getElementById('root');
if (!container) throw new Error('App container not found');
const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

document.title = isDev ? `💻 Dev • ${document.title}` : `${document.title} • ${brand.name}`;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
