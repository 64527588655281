import React, { ChangeEvent, FocusEvent, FormEvent, useState, useRef } from 'react';

import { useTheme } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn, useMount, useUpdateEffect } from 'react-use';

import useService from '../../../api/api.useService';
import brand from '../../../brand';
import BackButton from '../../../components/BackButton';
import Form from '../../../components/Form';
import FormHeader from '../../../components/FormHeader';
import HorizontalStack from '../../../components/HorizontalStack';
import SubmitButton from '../../../components/SubmitButton';
import { LeftArrowIcon } from '../../../icons';
import { CONFIRMATION_CODE_LENGTH, IS_PHONE_CONFIRMED_CACHE_KEY, PHONE_NUMBER_CACHE_KEY } from '../signup.constants';
import { isCodeValid, replaceAt, buildPathname } from '../signup.helpers';
import { useStepper, useRedirectOnIncompleteInfo, useRideCode } from '../signup.hooks';
import { confirmPhoneNumber, submitPhoneNumber } from '../signup.services';

/**
 * Note about the confirmation code inputs:
 * All the logic based on focus aims to simulate the same behaviors as in a single input, notably regarding deletions
 */

/**
 * /!\ WARNING: potential race condition when re-asking a new confirmation code:
 * - if server takes time to respond while already sent the SMS containing the new code
 * - user may have already typed the new code
 *
 * => confirmation request will use new confirmation code with an outdated registration ID !
 * SOLUTION: make sure user can NOT use submit button if reSendLoading = true
 */

export default function Step2ConfirmPhoneNumber() {
  useRedirectOnIncompleteInfo();
  useStepper(50);

  const location = useLocation();
  const navigate = useNavigate();
  const rideCode = useRideCode();

  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [registrationId, setRegistrationId] = useState(location.state as string);
  const [activeFieldIndex, setActiveFieldIndex] = useState(0);
  // Handling code as array instead of string allows to preserve its length even after deletions
  const [code, setCode] = useState<(string | null)[]>(Array(CONFIRMATION_CODE_LENGTH).fill(null));
  const [codeError, setCodeError] = useState('');

  const focusField = (fieldIndex: number) => inputRefs.current[fieldIndex]?.focus();
  const isCurrentFieldEmpty = (fieldValue?: string | null) => !fieldValue || fieldValue.length === 0;

  const [confirmPhoneNumberState, doConfirmPhoneNumber, clearServiceErrors] = useService(confirmPhoneNumber);
  const { loading, error, value: phoneConfirmed } = confirmPhoneNumberState;

  const [submitPhoneNumberState, reSubmitPhoneNumber] = useAsyncFn(submitPhoneNumber);
  const { loading: reSendLoading, error: reSendError, value: newCodeSent } = submitPhoneNumberState;

  // Don't use MUI input's autoFocus, else it will interfere vs
  // next field focus change on rerenders
  useMount(() => focusField(0));

  // Handles post-request behaviors
  useUpdateEffect(() => {
    if (loading || reSendLoading) return;
    if (error || reSendError) return;
    if (phoneConfirmed?.id) {
      // Set the confirmation timestamp in cache
      sessionStorage.setItem(IS_PHONE_CONFIRMED_CACHE_KEY, String(phoneConfirmed.attributes.phone_confirmed_at));
      // Pass the received registration id to next screen for its request payload
      navigate(buildPathname(rideCode, 'coordonnees'), { state: phoneConfirmed.id });
    }
  }, [loading, reSendLoading]);

  // Handles behaviors on code changes
  useUpdateEffect(() => {
    if (isCurrentFieldEmpty(code[activeFieldIndex])) {
      const previousIndex = Math.max(0, activeFieldIndex - 1);
      focusField(previousIndex);
    } else {
      const nextIndex = Math.min(CONFIRMATION_CODE_LENGTH, activeFieldIndex + 1);
      focusField(nextIndex);
    }
  }, [code]);

  // Handles behaviors when asking a new confirmation code:
  // we get the new registrationId from the request, while user inputs the new code
  useUpdateEffect(() => {
    const updatedRegistrationId = newCodeSent?.id;
    if (!updatedRegistrationId) {
      console.error('Re-asking new confirmation code resulted in an empty object, so no registration ID available.');
    } else setRegistrationId(updatedRegistrationId);
  }, [newCodeSent]);

  // Handles behaviors on error
  useUpdateEffect(() => {
    if (!error) return;
    focusField(0);
  }, [error]);

  function handleChange({ target }: ChangeEvent<HTMLInputElement>) {
    const { maxLength, value: inputValue } = target;
    if (inputValue?.trim().length <= maxLength) {
      clearErrors();
      const updatedCode = replaceAt(code, inputValue.toUpperCase(), activeFieldIndex);
      setCode(updatedCode);
    }
  }

  function handleFocus({ target }: FocusEvent<HTMLInputElement>) {
    target.select();
    const activeIndex = Number(target.name.split('-')[1]);
    setActiveFieldIndex(activeIndex);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isCodeValid(code.join(''))) {
      clearErrors();
      doConfirmPhoneNumber({ id: registrationId, phone_confirmation_code: code.join('') }).catch(console.error);
    } else setCodeError('Code de confirmation de téléphone erroné');
  }

  function clearErrors() {
    setCodeError('');
    clearServiceErrors();
  }

  function getAnotherCode() {
    const phone = sessionStorage.getItem(PHONE_NUMBER_CACHE_KEY);
    if (!phone) throw Error('Phone number not found in cache'); // Should never happen due to check from previous page
    if (loading || reSendLoading) return;
    reSubmitPhoneNumber({ code: rideCode, phone }).catch(console.error);
  }

  return (
    <>
      <BackButton icon={<LeftArrowIcon />} />

      <Form onSubmit={handleSubmit}>
        <FormHeader>Confirmez votre numéro de téléphone</FormHeader>

        <Typography variant="body2" textAlign="center" mb={4}>
          Nous venons de vous envoyer un code par SMS pour confirmer votre numéro de téléphone
        </Typography>

        <HorizontalStack spacing={2}>
          {Array.from({ length: CONFIRMATION_CODE_LENGTH }, (_, index) => (
            <SquaredInput
              key={index}
              name={`codeChar-${index}`}
              inputRef={(ref: HTMLInputElement) => (inputRefs.current[index] = ref)}
              inputProps={{ 'aria-label': `Caractère ${index + 1} du code`, maxLength: 1 }}
              error={Boolean(codeError || error?.message)}
              onInput={handleChange}
              onFocus={handleFocus}
            />
          ))}
        </HorizontalStack>

        {codeError || error?.message ? (
          <Typography variant="body2" textAlign="center" color="error" my={2}>
            {codeError || error?.message}
          </Typography>
        ) : null}

        <AskForAnotherCodeButton disabled={loading || reSendLoading} onClick={getAnotherCode}>
          Je n'ai pas reçu le code, cliquez ici pour recevoir un nouveau SMS
        </AskForAnotherCodeButton>

        <SubmitButton
          disabled={loading || reSendLoading || !isCodeValid(code.join(''))}
          loading={loading || reSendLoading}>
          Valider
        </SubmitButton>
      </Form>
    </>
  );
}

const SquaredInput = styled(OutlinedInput)({
  '& .MuiInputBase-input': {
    height: '2.5rem',
    padding: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '2.5rem',
  },
});

const AskForAnotherCodeButton = ({ children, disabled, ...props }: ButtonProps) => {
  const {
    typography: { fontSizeMedium },
  } = useTheme();
  return (
    <Button
      variant="text"
      sx={{ mt: 2, fontSize: fontSizeMedium, color: disabled ? grey[200] : brand.palette.primary.main }}
      {...props}>
      {children}
    </Button>
  );
};
