import React from 'react';

import { Stack, Typography } from '@mui/material';
import Link from '@mui/material/Link';

import brand from '../brand';

export default function NotFound() {
  return (
    <Stack alignItems="center" flex={1}>
      <img src="/notFound.png" alt="not found" />

      <Typography variant="h5" component="h1" mt={2} mb={1}>
        Page introuvable
      </Typography>

      <Typography variant="body1" textAlign="center" mb={5}>
        Désolé, la page que vous cherchez ne semble pas accessible.
      </Typography>

      <Typography variant="body1" textAlign="center">
        Vous pouvez joindre notre assistance au{' '}
        <Link href={`tel:${brand.supportPhoneNumber}`}>{brand.supportPhoneNumber}</Link> ou par mail à l'adresse{' '}
        <Link href={`mailto:${brand.supportMail}`}>{brand.supportMail}</Link>
      </Typography>
    </Stack>
  );
}
