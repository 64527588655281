import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import Legal from './routes/Legal';
import NotFound from './routes/NotFound';
import Root from './routes/Root';
import Step1SubmitPhoneNumber from './routes/signup/pages/Step1SubmitPhoneNumber';
import Step2ConfirmPhoneNumber from './routes/signup/pages/Step2ConfirmPhoneNumber';
import Step3CompleteSignup from './routes/signup/pages/Step3CompleteSignup';
import Step4SignupSuccess from './routes/signup/pages/Step4SignupSuccess';
import SignupRoot from './routes/signup/Signup';
import { RIDE_CODE_PARAM } from './routes/signup/signup.constants';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Root />}>
          <Route path={`i/:${RIDE_CODE_PARAM}`} element={<SignupRoot />}>
            <Route index element={<Step1SubmitPhoneNumber />} />
            <Route path="confirmation_telephone" element={<Step2ConfirmPhoneNumber />} />
            <Route path="coordonnees" element={<Step3CompleteSignup />} />
            <Route path="succes" element={<Step4SignupSuccess />} />
          </Route>

          <Route path="mentions_legales" element={<Legal />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
