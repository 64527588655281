import { getValueFromEnvironmentOrThrow } from './api.helpers';

// Endpoints
export const enum API {
  REGISTRATIONS = 'registrations',
  USERS = 'users',
  /*
  OTHER_API: 'other-api',
  etc...
  */
}

export const DOMAIN = getValueFromEnvironmentOrThrow('domain');

export const ECOV_API_KEY = getValueFromEnvironmentOrThrow('ecov_api_key');
