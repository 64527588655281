import React, { CSSProperties } from 'react';

import { useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import useService from '../../../api/api.useService';
import brand from '../../../brand';
import Form from '../../../components/Form';
import FormHeader from '../../../components/FormHeader';
import SubmitButton from '../../../components/SubmitButton';
import { PHONE_NUMBER_CACHE_KEY } from '../signup.constants';
import {
  buildPathname,
  cleanRawInternationalPhoneNumber,
  validateRawInternationalPhoneNumber,
} from '../signup.helpers';
import { useStepper, useRideCode } from '../signup.hooks';
import { SubmitPhoneNumberModel } from '../signup.interfaces';
import { submitPhoneNumber } from '../signup.services';

import 'react-phone-input-2/lib/style.css';

export default function Step1SubmitPhoneNumber() {
  useStepper(25);

  const rideCode = useRideCode();
  const navigate = useNavigate();

  const [{ loading, error: serviceError, value: phoneSubmitted }, doSubmitPhoneNumber, clearServiceErrors] =
    useService(submitPhoneNumber);

  // Handles post-request behaviors
  useUpdateEffect(() => {
    if (loading) return;
    if (serviceError) return;
    if (phoneSubmitted) {
      sessionStorage.setItem(PHONE_NUMBER_CACHE_KEY, getValues('phoneNumber').replaceAll(/[^+\d]/g, ''));
      // Pass the received registration id to next screen for its request payload
      navigate(buildPathname(rideCode, 'confirmation_telephone'), { state: phoneSubmitted.id });
    }
  }, [loading]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid },
    clearErrors: clearFormErrors,
    getValues,
    setValue,
    trigger,
  } = useForm<SubmitPhoneNumberModel>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      phoneNumber: sessionStorage.getItem(PHONE_NUMBER_CACHE_KEY) || undefined,
    },
  });

  const submit = async ({ phoneNumber }: SubmitPhoneNumberModel) => {
    clearErrors();
    await doSubmitPhoneNumber({ code: rideCode, phone: phoneNumber.replaceAll(/[^+\d]/g, '') });
  };

  const clearErrors = () => {
    clearFormErrors();
    clearServiceErrors();
  };

  const hasPhoneError = !!formErrors.phoneNumber || serviceError?.name.includes('phone');
  const serviceErrorMessage = serviceError?.message;

  const { required, ref } = register('phoneNumber', {
    required: 'Champ requis',
    validate: (phoneNumber) => validateRawInternationalPhoneNumber(phoneNumber) || 'Numéro invalide',
  });
  const phoneInputStyle = usePhoneInputStyle();

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <FormHeader>Indiquez votre numéro de téléphone</FormHeader>

      <PhoneInput
        country={brand.countries[0].code}
        onlyCountries={brand.countries.map((country) => country.code)}
        preserveOrder={['onlyCountries']}
        placeholder="Téléphone"
        inputProps={{ ref, required }}
        value={getValues('phoneNumber')}
        onChange={async (value) => {
          setValue('phoneNumber', cleanRawInternationalPhoneNumber(value));
          await trigger('phoneNumber', { shouldFocus: true });
        }}
        onFocus={() => clearErrors()}
        defaultMask={brand.countries[0].phoneMask}
        {...phoneInputStyle}
      />

      {hasPhoneError && serviceErrorMessage ? (
        <Typography variant="caption" color="error">
          {serviceErrorMessage}
        </Typography>
      ) : null}

      {!hasPhoneError && serviceErrorMessage ? (
        <>
          <Typography variant="body1" textAlign="center" color="error" mt={4} mb={2}>
            {serviceError.message}
          </Typography>

          <Typography variant="body1" textAlign="center">
            Vous pouvez joindre notre assistance au{' '}
            <Link href={`tel:${brand.supportPhoneNumber}`}>{brand.supportPhoneNumber}</Link> ou par mail à l'adresse{' '}
            <Link href={`mailto:${brand.supportMail}`}>{brand.supportMail}</Link>
          </Typography>
        </>
      ) : (
        <Typography variant="body2" mt={4}>
          Votre numéro de téléphone est utilisé uniquement pour s'inscrire et se connecter à notre service. Il ne sera
          jamais utilisé à des fins commerciales.
        </Typography>
      )}

      <SubmitButton disabled={!isValid || !!serviceError} loading={loading}>
        Continuer
      </SubmitButton>
    </Form>
  );
}

const BUTTON_WIDTH = 64;
const INPUT_PADDING = 16;

function usePhoneInputStyle() {
  const {
    typography: { fontSizeMedium: fontSize },
    shape: { borderRadius },
  } = useTheme();

  const buttonStyle: CSSProperties = {
    borderBottomLeftRadius: borderRadius,
    borderTopLeftRadius: borderRadius,
    display: 'flex',
    justifyContent: 'center',
    width: BUTTON_WIDTH,
  };

  const containerStyle: CSSProperties = { display: 'flex', fontSize };

  const dropdownStyle: CSSProperties = { borderRadius, left: 0 };

  const inputStyle: CSSProperties = {
    borderRadius,
    boxSizing: 'content-box',
    fontSize,
    height: 24,
    padding: INPUT_PADDING,
    paddingLeft: BUTTON_WIDTH + INPUT_PADDING,
    width: '100%',
  };

  return { buttonStyle, containerStyle, dropdownStyle, inputStyle };
}
