import React from 'react';

import { useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { SelectIcon } from '../icons';

function BackgroundCircle({ sx, ...otherProps }: BoxProps & { size: number }) {
  const { size } = otherProps;
  return (
    <Box
      sx={{
        gridRow: 1,
        width: size,
        height: size,
        boxSizing: 'border-box',
        bgcolor: 'secondary.light',
        borderRadius: '50%',
        ...sx,
      }}
      {...otherProps}
    />
  );
}

function ForegroundCircle({ sx, ...otherProps }: BoxProps & { checked: boolean }) {
  const { checked } = otherProps;
  const {
    typography: { fontSizeVerySmall, fontSizeMedium },
  } = useTheme();
  return (
    <BackgroundCircle
      size={checked ? fontSizeMedium : fontSizeVerySmall}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        bgcolor: 'secondary.main',
        ...sx,
      }}
      {...otherProps}>
      {checked && (
        <SelectIcon
          sx={{
            fontSize: fontSizeVerySmall,
            color: 'secondary.light',
          }}
        />
      )}
    </BackgroundCircle>
  );
}

export type Step = 0 | 25 | 50 | 75 | 100;

export default function Stepper({ sx, ...otherProps }: BoxProps & { step: Step }) {
  const { step } = otherProps;
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        gridTemplateColumns: `calc(25% - 8px) 16px calc(25% - 16px) 16px calc(25% - 16px) 16px calc(25% - 8px)`,
        ...sx,
      }}
      {...otherProps}>
      <BackgroundCircle size={16} sx={{ gridColumn: 2 }} />
      <BackgroundCircle size={16} sx={{ gridColumn: 4 }} />
      <BackgroundCircle size={16} sx={{ gridColumn: 6 }} />
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={step}
        sx={{
          width: '100%',
          gridColumn: '1/-1',
          gridRow: 1,
          alignSelf: 'center',
        }}
      />
      <ForegroundCircle checked={step >= 50} sx={{ gridColumn: 2 }} />
      {step >= 50 && <ForegroundCircle checked={step >= 75} sx={{ gridColumn: 4 }} />}
      {step >= 75 && <ForegroundCircle checked={step >= 100} sx={{ gridColumn: 6 }} />}
    </Box>
  );
}
