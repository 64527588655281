import { frFR } from '@mui/material/locale';
import { createTheme, ThemeOptions } from '@mui/material/styles';

import brand from './brand';

// Default theme values: https://mui.com/material-ui/customization/default-theme/#explore

const theme = createTheme({
  typography: {
    fontSizeSuperSmall: 10,
    fontSizeVerySmall: 12,
    fontSizeSmall: 14,
    fontSizeMedium: 16,
    fontSizeLarge: 20,
    fontSizeVeryLarge: 24,
    fontSizeSuperLarge: 32,
    fontSizeExtraLarge: 40,

    /* Default font weights used by MUI */
    // fontWeightLight: 300,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    // fontWeightBold: 700,
  },
});

export default createTheme(frFR, theme, {
  palette: {
    ...brand.palette,
    grey: {
      '200': '#E0E4EA',
      '300': '#B3BAC2',
      '400': '#737D81',
    },
    success: {
      main: '#00AD5C',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.fontSizeMedium,
    // body1 = default variant = 'allVariants'
    body2: {
      fontSize: theme.typography.fontSizeSmall,
      lineHeight: '20px',
    },
    h4: {
      fontSize: theme.typography.fontSizeSuperLarge,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '37.5px',
      letterSpacing: 1,
    },
    h5: {
      fontSize: theme.typography.fontSizeVeryLarge,
      fontWeight: theme.typography.fontWeightSemiBold,
      lineHeight: '32px',
    },
    h6: {
      fontSize: theme.typography.fontSizeLarge,
      fontWeight: theme.typography.fontWeightSemiBold,
      lineHeight: '28px',
    },
    subtitle1: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    subtitle2: {
      fontSize: theme.typography.fontSizeSmall,
      lineHeight: '20px',
    },
    button: {
      fontWeight: theme.typography.fontWeightSemiBold,
      lineHeight: '24px',
      textTransform: 'none',
      letterSpacing: 2,
    },
    caption: {
      fontSize: 12,
      lineHeight: '19px',
    },
    customVariant: {
      fontSize: 14,
      whiteSpace: 'nowrap',
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.size === 'large') {
            return {
              padding: '12px 22px',
              width: '100%',
            };
          } else if (ownerState.variant === 'text') {
            // used for links
            return {
              fontWeight: 'normal',
              lineHeight: '22px',
              letterSpacing: 'inherit',
              textDecorationLine: 'underline',
            };
          }
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.size === 'large') {
            return {
              padding: '12px 22px',
            };
          }
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        thickness: 5,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginInline: 0,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: brand.palette.secondary.light,
        },
      },
    },
  },
} as ThemeOptions);
