import React from 'react';

import brand from '../../brand';
import HorizontalStack from '../../components/HorizontalStack';

export default function Header() {
  return (
    <HorizontalStack component="header" justifyContent="center" mb={4}>
      <img src={brand.logoUrl} style={{ height: brand.logoHeight }} alt="Band Logo" />
    </HorizontalStack>
  );
}
