import React from 'react';

import { Divider, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';

import brand from '../brand';
import BackButton from '../components/BackButton';
import { CloseIcon } from '../icons';
import theme from '../theme';

const List = styled('ul')({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSizeMedium,
  lineHeight: '22px',
  paddingLeft: '1.5rem',
});

const SubTitle = ({ children }: TypographyProps) => (
  <Typography variant="subtitle1" component="h2">
    {children}
  </Typography>
);

const Paragraph = ({ children }: TypographyProps) => <Typography mt={2}>{children}</Typography>;

const Separator = () => <Divider sx={{ my: 3, backgroundColor: grey[200] }} />;

export default function Legal() {
  return (
    <>
      <BackButton icon={<CloseIcon />} toRight sx={{ top: 20, right: 20 }} />

      <Stack>
        <Typography variant="h4" component="h1" textAlign="center" mb={2}>
          Mentions légales
        </Typography>

        <SubTitle>Site Internet</SubTitle>

        <Paragraph>
          Le site Internet <Link href="/">{window.location.host}</Link> est édité par la société Ecov :
        </Paragraph>

        <List>
          <li>SAS au capital de 46 267 €</li>
          <li>Immatriculée au RCS de Nantes sous le numéro 808 203 467</li>
          <li>SIRET n° 808203467 00010</li>
          <li>Numéro de TVA intracommunautaire : FR 62 808203467</li>
          <li>Adresse du siège social : 4 place François II, 44 200 NANTES</li>
          <li>
            Téléphone : <Link href={`tel:${brand.supportPhoneNumber}`}>{brand.supportPhoneNumber}</Link>
          </li>
          <li>
            E-mail : <Link href="mailto:contact@ecov.fr">contact@ecov.fr</Link>
          </li>
        </List>

        <Paragraph>Le directeur de la publication est Monsieur Thomas Matagne.</Paragraph>

        <Paragraph>Le site a été créé par la société Ecov.</Paragraph>

        <Paragraph>
          La société Ecov est l’éditeur du présent site Internet à l’exception des pages relatives aux commentaires et
          notations, celles-ci étant directement publiées et mises en ligne par les utilisateurs du site, la société
          Ecov n’ayant alors qu’un rôle d’intermédiaire technique. Tout contenu illicite relevé dans ces éléments doit
          être signalé à la Société Ecov par le biais de l’adresse suivante :{' '}
          <Link href="mailto:contact@ecov.fr">contact@ecov.fr</Link>
        </Paragraph>

        <Separator />

        <SubTitle>Hébergement Technique</SubTitle>

        <Paragraph>
          Le site Internet est hébergé par la société OVH, 2 rue Kellermann BP 80157 59053 ROUBAIX CEDEX 1, RCS LILLE
          METROPOLE 424 761 419 00045.
        </Paragraph>

        <Paragraph>
          Il est rappelé que le fait pour toute personne de présenter à un hébergeur un contenu ou une activité comme
          étant illicite dans le but d’en obtenir le retrait ou d’en faire cesser la diffusion, alors qu’elle sait cette
          information inexacte, est puni d’une peine d’un an d’emprisonnement et de 15 000 € d’amende.
        </Paragraph>

        <Paragraph>
          Le présent site Internet, son contenu, ainsi que la marque « {brand.name} » sont protégés au titre de la
          propriété intellectuelle, selon les dispositions disponibles sur la page Internet du site relative aux
          Conditions Générales d'Utilisation et accessible depuis la dernière étape du formulaire d'inscription au
          service de covoiturage.
        </Paragraph>

        <Separator />

        <SubTitle>Données Personnelles</SubTitle>

        <Paragraph>
          La consultation du site est possible sans que vous ayez à révéler votre identité ou toute autre information à
          caractère personnel vous concernant.
        </Paragraph>

        <Paragraph>
          Les informations relatives à l'utilisation des données personnelles par Ecov sont disponibles sur la page
          Internet du site relative à la protection des données personnelles et accessible depuis la dernière étape du
          formulaire d'inscription au service de covoiturage.
        </Paragraph>
      </Stack>
    </>
  );
}
