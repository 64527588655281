import React, { useState } from 'react';

import { Outlet } from 'react-router-dom';

import Stepper, { Step } from '../../components/Stepper';

/**
 * Process order is :
 * 1. SubmitPhoneNumber
 * 2. ConfirmPhoneNumber
 * 3. CompleteSignup
 * 3. SignupSuccess
 */
export default function SignupRoot() {
  const [activeStep, setActiveStep] = useState<Step | null>(null);

  return (
    <>
      {activeStep ? <Stepper step={activeStep} mb={4} /> : null}

      <Outlet context={setActiveStep} />
    </>
  );
}
