import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function CancelIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0151 8.4293C18.4056 8.03878 18.4056 7.40561 18.0151 7.01509L16.9849 5.98486C16.5944 5.59433 15.9612 5.59433 15.5707 5.98486L12 9.55553L8.42933 5.98485C8.0388 5.59433 7.40564 5.59433 7.01511 5.98485L5.98488 7.01509C5.59435 7.40561 5.59435 8.03878 5.98488 8.4293L9.55555 12L5.98488 15.5707C5.59435 15.9612 5.59435 16.5943 5.98488 16.9849L7.01511 18.0151C7.40563 18.4056 8.0388 18.4056 8.42932 18.0151L12 14.4444L15.5707 18.0151C15.9612 18.4056 16.5944 18.4056 16.9849 18.0151L18.0151 16.9849C18.4056 16.5943 18.4056 15.9612 18.0151 15.5707L14.4445 12L18.0151 8.4293Z"
      />
    </SvgIcon>
  );
}

export function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.61143L18.3886 4L12 10.3886L5.61143 4L4 5.61143L10.3886 12L4 18.3886L5.61143 20L12 13.6114L18.3886 20L20 18.3886L13.6114 12L20 5.61143Z"
      />
    </SvgIcon>
  );
}

export function LeftArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M21 11H6.83L10.41 7.41L9 6L3 12L9 18L10.41 16.59L6.83 13H21V11Z" />
    </SvgIcon>
  );
}

export function PhoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M8.87211 5.89028C8.63965 5.3396 8.44475 4.78041 8.29237 4.20209C8.0025 3.10214 7.10737 2.26584 5.9897 2.05251C4.87203 1.83918 3.73168 2.28639 3.05697 3.20278C2.2717 4.27012 1.99033 5.56356 2.00025 6.84494C2.01088 8.12987 2.31564 9.41905 2.76426 10.6083C3.66294 12.9896 5.22852 15.2108 7.00886 16.9911C8.78919 18.7714 11.0096 20.3363 13.391 21.235C14.5809 21.6843 15.8694 21.9891 17.1551 21.9997C18.4364 22.0103 19.7299 21.729 20.7972 20.943C21.7136 20.2683 22.1608 19.1279 21.9475 18.0103C21.7342 16.8926 20.8979 15.9975 19.7979 15.7076C19.2196 15.5552 18.6604 15.3603 18.1097 15.1279C17.199 14.7437 16.1543 14.8571 15.3471 15.4269C14.9651 15.697 14.6178 15.9968 14.296 16.3363C11.2875 16.0889 7.91036 12.7118 7.66301 9.70325C8.00179 9.38148 8.30229 9.03421 8.57232 8.6522C9.14284 7.84567 9.25624 6.801 8.87211 5.89028Z" />
    </SvgIcon>
  );
}

export function SelectIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43431 17.7643L4.2343 12.5344C3.9219 12.2202 3.9219 11.7108 4.2343 11.3965L5.36565 10.2587C5.67806 9.94442 6.18462 9.94442 6.49703 10.2587L10 13.7817L17.503 6.23565C17.8154 5.92145 18.3219 5.92145 18.6343 6.23565L19.7657 7.37353C20.0781 7.68774 20.0781 8.19718 19.7657 8.51141L10.5657 17.7644C10.2532 18.0786 9.74672 18.0786 9.43431 17.7643Z"
      />
    </SvgIcon>
  );
}
