import React from 'react';

import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import type {} from '@mui/lab/themeAugmentation';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

import theme from '../theme';

import Footer from './layout/Footer';
import Header from './layout/Header';

export default function Root() {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={theme}>
        <Stack pt={5} pb={3} px={2} minHeight="100vh" sx={{ boxSizing: 'border-box' }}>
          <Header />

          <Stack component="main" alignItems="center" width="100%" flex={1}>
            <Outlet />
          </Stack>

          <Footer />
        </Stack>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
