import React from 'react';

import Typography, { TypographyProps } from '@mui/material/Typography';

export default function FormHeader({ children }: TypographyProps) {
  return (
    <Typography variant="h6" component="h1" textAlign="center" mb={3} width="70vw">
      {children}
    </Typography>
  );
}
