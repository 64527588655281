import React, { FormHTMLAttributes } from 'react';

import styled from '@mui/system/styled';

const BaseForm = styled('form')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

export default function Form({ children, ...otherProps }: FormHTMLAttributes<HTMLFormElement>) {
  return <BaseForm {...otherProps}>{children}</BaseForm>;
}
