import { ECOV_API_KEY } from './api.constants';
import { FetchArgs, HttpFacade, HttpMethod, TypedRequestInit, TypedResponse } from './api.interfaces';

const defaultHeaders: HeadersInit = {
  'Content-Type': 'application/json;charset=UTF-8',
  'x-ecov-apikey': ECOV_API_KEY,
};

function extendedFetch<RequestPayload, ResponsePayload>(
  url: FetchArgs[0],
  options?: TypedRequestInit<RequestPayload>,
): Promise<TypedResponse<ResponsePayload>> {
  return window.fetch(url, { headers: defaultHeaders, ...options });
}

// TODO: Make Error management generic, putting try/catch into extendedFetch

export const http: HttpFacade = {
  [HttpMethod.GET]: extendedFetch,

  [HttpMethod.DELETE]: (url, options) => extendedFetch(url, { ...options, method: HttpMethod.DELETE }),

  [HttpMethod.PATCH]: (url, options) => extendedFetch(url, { ...options, method: HttpMethod.PATCH }),

  [HttpMethod.POST]: (url, options) => extendedFetch(url, { ...options, method: HttpMethod.POST }),

  [HttpMethod.PUT]: (url, options) => extendedFetch(url, { ...options, method: HttpMethod.PUT }),
};
