import { http } from '../../api/api.baseFetch';
import { API, DOMAIN } from '../../api/api.constants';
import { buildPayload, errorHandler } from '../../api/api.helpers';
import brand from '../../brand';

import {
  CompleteSignupErrorCodes,
  CompleteSignupPayload,
  CompleteSignupResult,
  ConfirmPhoneNumberErrorCodes,
  ConfirmPhoneNumberPayload,
  ConfirmPhoneNumberResult,
  SubmitPhoneNumberErrorCodes,
  SubmitPhoneNumberPayload,
  SubmitPhoneNumberResult,
} from './signup.interfaces';

/**
 * Process order is :
 * 1. SubmitPhoneNumber
 * 2. ConfirmPhoneNumber
 * 3. CompleteSignup
 * 4. SignupSuccess
 */

// /!\ Important Note: When defining Payload interface, always use the one defined by the backend.
// It is not the responsibility of services to format input data, they just deliver (data or errors), nothing else ;)

export async function submitPhoneNumber({ code, phone }: SubmitPhoneNumberPayload): Promise<SubmitPhoneNumberResult> {
  const payload = buildPayload<SubmitPhoneNumberPayload>({ code, phone });

  try {
    const response = await http.POST<SubmitPhoneNumberPayload, SubmitPhoneNumberResult>(
      `${DOMAIN}/${API.REGISTRATIONS}`,
      {
        body: JSON.stringify(payload),
      },
    );

    if (!response.headers.get('Content-Type')?.includes('json')) {
      throw new Error('Received a non-JSON response');
    }

    const { data, errors } = await response.json();

    if (errors?.length) {
      console.error('Business Errors', errors);
      throw new Error(errors[0].code);
    }

    return data;
  } catch (err) {
    const badLinkMessage = "Désolé, votre lien d'inscription semble avoir déjà été utilisé ou inexistant";
    const businessErrorsMap: Record<SubmitPhoneNumberErrorCodes, string> = {
      [SubmitPhoneNumberErrorCodes.INVALID_PHONE_FORMAT]: 'Numéro invalide',
      [SubmitPhoneNumberErrorCodes.PHONE_ALREADY_USED]: 'Numéro déjà enregistré',
      [SubmitPhoneNumberErrorCodes.REQUIRED]: badLinkMessage,
      [SubmitPhoneNumberErrorCodes.RIDE_NOT_FOUND]: badLinkMessage,
      [SubmitPhoneNumberErrorCodes.UNAVAILABLE_CODE_STATE]: badLinkMessage,
      [SubmitPhoneNumberErrorCodes.USED_CODE_STATE]: badLinkMessage,
    };
    throw errorHandler<SubmitPhoneNumberErrorCodes>(err, businessErrorsMap);
  }
}

/**
 * /!\ When requesting a new code, make sure to get the associated registration id
 * from previous service 'submitPhoneNumber'
 */
export async function confirmPhoneNumber({
  id, // registration id
  phone_confirmation_code,
}: ConfirmPhoneNumberPayload): Promise<ConfirmPhoneNumberResult> {
  const payload = {
    data: {
      type: 'registration',
      id,
      attributes: { phone_confirmation_code },
    },
  };

  try {
    const response = await http.PATCH<ConfirmPhoneNumberPayload, ConfirmPhoneNumberResult>(
      `${DOMAIN}/${API.REGISTRATIONS}/${id}`,
      {
        body: JSON.stringify(payload),
      },
    );

    if (!response.headers.get('Content-Type')?.includes('json')) {
      throw new Error('Received a non-JSON response');
    }

    const { data, errors } = await response.json();

    if (errors?.length) {
      console.error('Business Errors', errors);
      throw new Error(errors[0].code);
    }

    return data;
  } catch (err) {
    const businessErrorsMap: Record<ConfirmPhoneNumberErrorCodes, string> = {
      [ConfirmPhoneNumberErrorCodes.INVALID_PHONE_CONFIRMATION_CODE]: 'Code de confirmation de téléphone erroné',
    };
    throw errorHandler<ConfirmPhoneNumberErrorCodes>(err, businessErrorsMap);
  }
}

export async function completeRegistration({
  registrationId,
  birth_date,
  email,
  first_name,
  last_name,
  password,
  phone,
}: CompleteSignupPayload): Promise<CompleteSignupResult> {
  const payload = {
    data: {
      type: 'user',
      attributes: {
        birth_date,
        email,
        first_name,
        last_name,
        password,
        phone,
      },
      relationships: {
        brand: {
          data: { type: 'brand', id: brand.id },
        },
        registration: {
          data: { type: 'registration', id: registrationId },
        },
      },
    },
  };

  try {
    const response = await http.POST<CompleteSignupPayload, CompleteSignupResult>(`${DOMAIN}/${API.USERS}`, {
      body: JSON.stringify(payload),
    });

    if (!response.headers.get('Content-Type')?.includes('json')) {
      throw new Error('Received a non-JSON response');
    }

    const { data, errors } = await response.json();

    if (errors?.length) {
      console.error('Business Errors', errors);
      throw new Error(`${errors[0].source.pointer.replace('/data/', '')}:${errors[0].code}`);
    }

    return data;
  } catch (err) {
    const businessErrorsMap: Record<CompleteSignupErrorCodes, string> = {
      [CompleteSignupErrorCodes.ALREADY_REGISTERED_EMAIL]: 'Email déjà existant',
      [CompleteSignupErrorCodes.ALREADY_REGISTERED_PHONE]: 'Numéro déjà enregistré',
      [CompleteSignupErrorCodes.BADLY_FORMATTED_EMAIL]: 'Email invalide',
      [CompleteSignupErrorCodes.BADLY_FORMATTED_PASSWORD]: 'Mot de passe invalide',
      [CompleteSignupErrorCodes.BADLY_FORMATTED_PHONE]: 'Numéro invalide',
      [CompleteSignupErrorCodes.BRANDS_NOT_MATCHING]:
        "La marque blanche utilisée pour cette inscription n'est pas la même que celle transmise",
      [CompleteSignupErrorCodes.CODE_ALREADY_USED]: 'Le code trajet a déjà été utilisé',
      [CompleteSignupErrorCodes.CODE_UNAVAILABLE]: 'Code trajet non disponible',
      [CompleteSignupErrorCodes.EMPTY_REQUIRED_FIELD_BIRTHDATE]: 'Champ obligatoire',
      [CompleteSignupErrorCodes.EMPTY_REQUIRED_FIELD_EMAIL]: 'Champ obligatoire',
      [CompleteSignupErrorCodes.EMPTY_REQUIRED_FIELD_FIRSTNAME]: 'Champ obligatoire',
      [CompleteSignupErrorCodes.EMPTY_REQUIRED_FIELD_LASTNAME]: 'Champ obligatoire',
      [CompleteSignupErrorCodes.EMPTY_REQUIRED_FIELD_PASSWORD]: 'Champ obligatoire',
      [CompleteSignupErrorCodes.EMPTY_REQUIRED_FIELD_PHONE]: 'Champ obligatoire',
      [CompleteSignupErrorCodes.ILLEGAL_AGE]: "Âge inférieur à l'âge légal de conduite",
      [CompleteSignupErrorCodes.MISSING_BRAND_ID]: 'Identifiant de marque blanche manquant',
      [CompleteSignupErrorCodes.MISSING_REGISTRATION_ID]: "Identifiant d'inscription manquant",
      [CompleteSignupErrorCodes.PHONE_NOT_CONFIRMED]: 'Numéro de téléphone non confirmé',
    };
    throw errorHandler<CompleteSignupErrorCodes>(err, businessErrorsMap);
  }
}
