/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
// @see: https://github.com/streamich/react-use/blob/master/docs/useAsyncFn.md

/** Extends the original react-use.useAsyncFn with ability to clear errors, usefull in forms */
import { DependencyList, useCallback, useRef, useState } from 'react';

import { useMountedState } from 'react-use';
import type { FunctionReturningPromise, PromiseType } from 'react-use/lib/misc/types';

export type AsyncState<T> =
  | {
      loading: boolean;
      error?: undefined;
      value?: undefined;
    }
  | {
      loading: true;
      error?: Error | undefined;
      value?: T;
    }
  | {
      loading: false;
      error: Error;
      value?: undefined;
    }
  | {
      loading: false;
      error?: undefined;
      value: T;
    };

type StateFromFunctionReturningPromise<T extends FunctionReturningPromise> = AsyncState<PromiseType<ReturnType<T>>>;

export type AsyncFnReturn<T extends FunctionReturningPromise = FunctionReturningPromise> = [
  StateFromFunctionReturningPromise<T>,
  T,
  () => void,
];

/**
 * @usage: https://github.com/streamich/react-use/blob/master/docs/useAsyncFn.md
 */
export default function useService<T extends FunctionReturningPromise>(
  fn: T,
  deps: DependencyList = [],
  initialState: StateFromFunctionReturningPromise<T> = { loading: false },
): AsyncFnReturn<T> {
  const lastCallId = useRef(0);
  const isMounted = useMountedState();
  const [state, set] = useState<StateFromFunctionReturningPromise<T>>(initialState);

  const clearServiceErrors = () => set({ error: undefined, loading: false });

  const callback = useCallback((...args: Parameters<T>): ReturnType<T> => {
    const callId = ++lastCallId.current;

    if (!state.loading) {
      set((prevState) => ({ ...prevState, loading: true }));
    }

    return fn(...args).then(
      (value) => {
        isMounted() && callId === lastCallId.current && set({ value, loading: false });
        return value;
      },
      (error) => {
        isMounted() && callId === lastCallId.current && set({ error, loading: false });
        return error;
      },
    ) as ReturnType<T>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [state, callback as unknown as T, clearServiceErrors];
}
