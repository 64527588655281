import React from 'react';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import useService from '../../../api/api.useService';
import brand from '../../../brand';
import BackButton from '../../../components/BackButton';
import Form from '../../../components/Form';
import FormHeader from '../../../components/FormHeader';
import PasswordField from '../../../components/PasswordField';
import Spacer from '../../../components/Spacer';
import SubmitButton from '../../../components/SubmitButton';
import { LeftArrowIcon } from '../../../icons';
import { EMAIL_PATTERN, PASSWORD_ALLOWED_CHARACTERS, PHONE_NUMBER_CACHE_KEY } from '../signup.constants';
import { buildPathname, isLegalForDriving, isValidDate } from '../signup.helpers';
import { useStepper, useRedirectOnIncompleteInfo, useRideCode } from '../signup.hooks';
import { CompleteSignupModel } from '../signup.interfaces';
import { completeRegistration } from '../signup.services';

export default function Step3CompleteSignup() {
  useRedirectOnIncompleteInfo();
  useStepper(75);

  const location = useLocation();
  const navigate = useNavigate();
  const rideCode = useRideCode();

  const [{ loading, error, value }, doCompleteSignup, clearServiceErrors] = useService(completeRegistration);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid },
    clearErrors: clearFormErrors,
  } = useForm<CompleteSignupModel>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  // Handles post-request behaviors
  useUpdateEffect(() => {
    if (loading) return;
    if (error) return;
    if (value) {
      navigate(buildPathname(rideCode, 'succes'));
    }
  }, [loading]);

  async function submit({ birthDate, email, firstName, lastName, password }: CompleteSignupModel) {
    clearErrors();
    const phone = sessionStorage.getItem(PHONE_NUMBER_CACHE_KEY);
    if (!phone) throw Error('Phone number not found in cache'); // Should never happen due to check on mount from useRedirectOnIncompleteInfo hook
    await doCompleteSignup({
      registrationId: location.state as string,
      birth_date: new Date(birthDate).toLocaleDateString(), // format "21/01/2022"
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      phone,
    });
  }

  function clearErrors(fieldName?: keyof CompleteSignupModel) {
    clearFormErrors(fieldName);
    clearServiceErrors();
  }

  const isErrorDisplayedInHelperText = /first_name|last_name|birthdate|email|password/.test(error?.name || '');

  return (
    <>
      <BackButton icon={<LeftArrowIcon />} />

      <Form onSubmit={handleSubmit(submit)}>
        <FormHeader>Créez votre compte</FormHeader>

        <Stack justifyContent="space-between" spacing={4} flex={1}>
          <TextField
            {...register('firstName', {
              required: 'Champ requis',
            })}
            type="text"
            label="Prénom"
            onFocus={() => clearErrors('firstName')}
            error={Boolean(formErrors.firstName || error?.name.includes('first_name'))}
            helperText={formErrors.firstName?.message || (error?.name.includes('first_name') ? error?.message : '')}
          />

          <TextField
            {...register('lastName', {
              required: 'Champ requis',
            })}
            type="text"
            label="Nom"
            onFocus={() => clearErrors('lastName')}
            error={Boolean(formErrors.lastName || error?.name.includes('last_name'))}
            helperText={formErrors.lastName?.message || (error?.name.includes('last_name') ? error?.message : '')}
          />

          <TextField
            {...register('birthDate', {
              required: 'Champ requis',
              valueAsDate: true,
              validate: {
                isValidDate,
                isLegalForDriving,
              },
            })}
            type="date"
            label="Date de naissance"
            onFocus={() => clearErrors('birthDate')}
            error={Boolean(formErrors.birthDate || error?.name.includes('birthdate'))}
            helperText={formErrors.birthDate?.message || (error?.name.includes('birthdate') ? error?.message : '')}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            {...register('email', {
              required: 'Champ requis',
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Email invalide',
              },
            })}
            type="email"
            label="Email"
            onFocus={() => clearErrors('email')}
            error={Boolean(formErrors.email || error?.name.includes('email'))}
            helperText={formErrors.email?.message || (error?.name.includes('email') ? error?.message : '')}
          />

          <PasswordField
            {...register('password', {
              required: 'Champ requis',
              pattern: {
                value: PASSWORD_ALLOWED_CHARACTERS,
                message: 'Mot de passe invalide',
              },
            })}
            onFocus={() => clearErrors('password')}
            errorMessage={formErrors.password?.message || (error?.name.includes('password') ? error?.message : '')}
          />
        </Stack>

        <Spacer />

        <RGPD />

        {!isErrorDisplayedInHelperText && error?.message ? (
          <Typography variant="body2" color="error" mt={4}>
            {error.message}
          </Typography>
        ) : null}

        <SubmitButton disabled={!isValid} loading={loading}>
          Valider
        </SubmitButton>
      </Form>
    </>
  );
}

const RGPD = () => (
  <Typography variant="body1" mt={4}>
    En créant votre compte vous acceptez les{' '}
    <Link href={brand.eulaUrl} target="_blank" rel="noopener noreferrer">
      conditions générales d’utilisation du service {brand.name}
    </Link>{' '}
    et la{' '}
    <Link href={brand.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
      politique de protection des données personnelles
    </Link>{' '}
    et vous certifiez être titulaire d’un permis de conduire valide pour utiliser le service en tant que conducteur.
  </Typography>
);
