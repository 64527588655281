export const RIDE_CODE_PARAM = 'rideCode';

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const PASSWORD_MINLENGTH = 6;
export const PASSWORD_ALLOWED_CHARACTERS = new RegExp(`^[\\w @$&_():;!#\\-/%?]{${PASSWORD_MINLENGTH},}`);

export const CONFIRMATION_CODE_LENGTH = 4;

export const PHONE_NUMBER_CACHE_KEY = 'SignupContext#phoneNumber';

export const IS_PHONE_CONFIRMED_CACHE_KEY = 'SignupContext#isPhoneConfirmed';
