export enum HttpMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export type EmptyObject = Record<string, never>;

// typeof function extendedFetch from 'api.baseFetch.ts'
// type ExtendedFetch = <RequestPayload, ResponsePayload>(
//   url: RequestInfo,
//   options?: TypedRequestInit<RequestPayload> | undefined,
// ) => Promise<TypedResponse<ResponsePayload>>;

// Each exposed method has same interface as ExtendedFetch but without method
export type HttpFacade = {
  [M in HttpMethod]: <TRequestPayload, TResponsePayload>(
    url: FetchArgs[0],
    options?: Omit<TypedRequestInit<TRequestPayload>, 'method'>,
  ) => Promise<TypedResponse<TResponsePayload>>;
};

type Relationships = Record<string, JsonApiPayload | never>;
export interface JsonApiPayload<TAttributes = undefined> {
  data: {
    type: string;
    id?: number;
    attributes?: TAttributes;
    relationships?: Relationships;
  };
}

interface JsonApiError {
  status: string; // ex: '422'
  source: {
    pointer: string;
  };
  title: string;
  detail: string; // ex: "Code n'est pas lié à un ride"
  code: string; // ex: 'ride_not_found'
}

/** Intermediate export interface defining the format of the response, within Response */
export interface JsonApiResult<TResponsePayload> {
  data: TResponsePayload;
  links: {
    self: string; // '/' + API;
  };
  meta: {
    info: null;
    deprecation: null;
  };
  errors: JsonApiError[];
}

export type FetchArgs = Parameters<typeof window.fetch>;

interface TypedJSON<TPayload> extends JSON {
  stringify(p: TPayload): string;
}

/** Overrides RequestInit parameter with typed payload */
export interface TypedRequestInit<TRequestPayload> extends RequestInit {
  body?: ReturnType<TypedJSON<TRequestPayload>['stringify']>;
}

/** Overrides Response with expected typed result */
export interface TypedResponse<ResponsePayload> extends Response {
  json(): Promise<JsonApiResult<ResponsePayload>>;
}
