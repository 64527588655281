import { Step } from '../../components/Stepper';

export type RideCodePath = '' | 'confirmation_telephone' | 'coordonnees' | 'succes';

export enum SubmitPhoneNumberErrorCodes {
  INVALID_PHONE_FORMAT = 'invalid_phone_format',
  PHONE_ALREADY_USED = 'phone_already_used',
  REQUIRED = 'required',
  RIDE_NOT_FOUND = 'ride_not_found',
  UNAVAILABLE_CODE_STATE = 'unavailable_code_state',
  USED_CODE_STATE = 'used_code_state',
}

export enum ConfirmPhoneNumberErrorCodes {
  INVALID_PHONE_CONFIRMATION_CODE = 'invalid_phone_confirmation_code',
}

/** Mapping based on pointer:code returned from JsonAPI */
export enum CompleteSignupErrorCodes {
  ALREADY_REGISTERED_EMAIL = 'attributes/email:taken', // email déjà pris
  ALREADY_REGISTERED_PHONE = 'attributes/phone:taken', // phone déjà pris
  BADLY_FORMATTED_EMAIL = 'attributes/email:invalid', // mauvais format
  BADLY_FORMATTED_PASSWORD = 'attributes/password:invalid', // mauvais format
  BADLY_FORMATTED_PHONE = 'attributes/phone:invalid', // mauvais format
  BRANDS_NOT_MATCHING = 'relationships/registration:invalid', // la brand de la registration n'est pas la même que la brand envoyée en paramètre
  CODE_ALREADY_USED = 'relationships/registration:used_code_state', // Le code trajet a déjà été utilisé
  CODE_UNAVAILABLE = 'relationships/registration:unavailable_code_state', // Le code trajet n'est pas disponible
  EMPTY_REQUIRED_FIELD_BIRTHDATE = 'attributes/birthdate:blank', // champ requis
  EMPTY_REQUIRED_FIELD_EMAIL = 'attributes/email_name:blank', // champ requis
  EMPTY_REQUIRED_FIELD_FIRSTNAME = 'attributes/first_name:blank', // champ requis
  EMPTY_REQUIRED_FIELD_LASTNAME = 'attributes/last_name:blank', // champ requis
  EMPTY_REQUIRED_FIELD_PASSWORD = 'attributes/password:blank', // champ requis
  EMPTY_REQUIRED_FIELD_PHONE = 'attributes/phone_name:blank', // champ requis
  ILLEGAL_AGE = 'attributes/birthdate:less_than', // âge < 18 ans
  MISSING_BRAND_ID = 'relationships/brand:required', // il manque le paramètre brand
  MISSING_REGISTRATION_ID = 'relationships/registration:required', // il manque le paramètre registration
  PHONE_NOT_CONFIRMED = 'relationships/registration:phone_not_confirmed', // numéro de téléphone non confirmé
}

export type SetActiveStep = (step: Step) => void;

export interface SubmitPhoneNumberModel {
  phoneNumber: string;
}

export interface CompleteSignupModel {
  firstName: string;
  lastName: string;
  birthDate: string; // /!\ string Date;
  email: string;
  password: string;
}

export type SubmitPhoneNumberPayload = {
  code: string; // ride code
  phone: string;
};

export interface SubmitPhoneNumberResult {
  id: string; // registration's ID
  type: 'registration';
  attributes: {
    phone_confirmed_at: null;
  };
  links: {
    self: string;
  };
}

export type ConfirmPhoneNumberPayload = {
  id: string;
  phone_confirmation_code: string; // confirmation code received by SMS
};

export interface ConfirmPhoneNumberResult {
  id: string; // registration's ID
  type: 'registration';
  attributes: {
    phone_confirmed_at: Date;
  };
  links: {
    self: string;
  };
}

export type CompleteSignupPayload = {
  [K in 'registrationId' | 'phone' | 'first_name' | 'last_name' | 'email' | 'birth_date' | 'password']: string;
};

export interface CompleteSignupResult {
  id: string;
  type: 'user';
  attributes: {
    phone_confirmed_at: Date;
  };
  links: {
    self: string;
  };
}
