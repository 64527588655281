import React from 'react';

import { Typography } from '@mui/material';

import brand from '../../../brand';
import FormHeader from '../../../components/FormHeader';
import HeldPhoneWithShapes from '../../../components/HeldPhoneWithShapes';
import SubmitButton from '../../../components/SubmitButton';
import { useStepper } from '../signup.hooks';

export default function Step4SignupSuccess() {
  useStepper(100);

  return (
    <>
      <FormHeader>Votre compte est créé !</FormHeader>

      <HeldPhoneWithShapes />

      <Typography variant="body1" textAlign="center" mt={3}>
        Pour utiliser prochainement le service et obtenir vos gains, nous vous invitons à télécharger l'application{' '}
        {brand.name}
      </Typography>

      <SubmitButton href={brand.downloadUrl}>Télécharger l'application</SubmitButton>
    </>
  );
}
